<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title">{{ $t('Notifications.title.Notice settings') }}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <section class="section__container container container_active separator-when-scrolling pfull__12">
                <h3 class="form-subtitle mb__12">{{ $t('Notifications.E-mail for sending notifications') }}:</h3>

                <div class="horizontal-line mb__12"
                     v-for="(email,i) in (notif_settings.emails || [])"
                     :key="'email-'+i"
                >
                    <FieldGroup_input
                            :id="'email-'+i"
                            :label="$t('Notifications.E-mail')"
                            :error="(errors.emails || [])[i]"
                            v-model="notif_settings.emails[i]"
                            :data-x="email"
                    />
                    <!-- {{email}} -->
                    <button class="button button_view_icon button_theme_base ml__8" style="display: block"
                            v-if="i==notif_settings.emails.length-1 && i < 4 && notif_settings.emails[i].length"
                            @click="addEmail()"
                    >
                        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__add_lg"/></span> </span>
                    </button>
                    <button class="button button_view_icon button_theme_base is_danger ml__8"
                            v-if="i!=notif_settings.emails.length"
                            @click="removeEmail(i)"
                    >
                        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete_lg"/></span> </span>
                    </button>
                </div>

                <h3 class="form-subtitle mb__12 mt__48">{{ $t('Notifications.Trigger notification') }}:</h3>
                <FieldGroup_input
                        :field-group_class="'field-group_width_200 mb__12'"
                        :id="'speed-limit'"
                        :label="$t('Notifications.Speed limit')+', '+speedUnits"
                        :error="errors['speed-limit']"
                        v-model="notif_settings['speed-limit']"
                />

                <FieldGroup_input
                        :field-group_class="'field-group_width_200 mb__12'"
                        :id="'no-signal-time'"
                        :label="$t('Notifications.No signal time')+', sec'"
                        :error="errors['no-signal-time']"
                        v-model="notif_settings['no-signal-time']"
                />

                <FieldGroup_input
                        :field-group_class="'field-group_width_200 mb__12'"
                        :id="'minimum-battery-charge'"
                        :label="$t('Notifications.Minimum battery charge')+', V'"
                        :error="errors['minimum-battery-charge']"
                        v-model="notif_settings['minimum-battery-charge']"
                />

            </section>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{ $t('buttons.Save') }} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
    import { email,integer,decimal }from 'vuelidate/lib/validators'
    const greaterThanZero = (value) => value > 0

    export default {
        name: "NotificationsProps",
        props: [],
        components: {},
        data() {
            return {
                notif_settings: {
                    emails: [],
                },
                errors: {},
            }
        },
        validations:{
            notif_settings: {
                'speed-limit': {integer, minValue: greaterThanZero},
                'no-signal-time': {integer, minValue: greaterThanZero},
                'minimum-battery-charge': {decimal, minValue: greaterThanZero},
                emails: {
                    $each: {
                        email
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                "getAppUser",
                "getAppUserSettings",
                "getAppUserUnits",
            ]),
            speedUnits(){
                return this.getAppUserUnits.speed
            },
        },
        methods: {
            ...mapMutations([
                "showMainList"
            ]),
            ...mapActions([
                "setSectionWidget",
                "saveAppUserNotificationsSettings",
                "saveAppUser",
                "setPopup",
            ]),
            validateSettigs() {
                //let components = this.components.map(c => c.component)
                this.$v.$touch()
                this.errors = {}
                this.componentsErrors = [];
                let hasError;
                if (this.$v.$invalid) {
                    //if(components.includes(''))
                    {
                        hasError = false
                        if (this.$v.notif_settings.emails.$anyError) {
                            this.errors.emails = []
                            for (const i in this.$v.notif_settings.emails.$each.$iter) {
                                this.errors.emails[i] = ''
                                if (!this.$v.notif_settings.emails.$each[i].email) {
                                    hasError =
                                    this.errors.emails[i] = 'Enter valid email'
                                }
                            }
                        }
                        if (!this.$v.notif_settings['speed-limit'].integer) {
                            hasError =
                            this.errors['speed-limit'] = 'Speed limit must be integer'
                        } else if (!this.$v.notif_settings['speed-limit'].minValue) {
                            hasError =
                            this.errors['speed-limit'] = 'Speed limit must be greater than zero'
                        }
                        if (!this.$v.notif_settings['no-signal-time'].integer) {
                            hasError =
                            this.errors['no-signal-time'] = 'No signal time must be integer'
                        } else if (!this.$v.notif_settings['no-signal-time'].minValue) {
                            hasError =
                            this.errors['no-signal-time'] = 'No signal time must be greater than zero'
                        }
                        if (!this.$v.notif_settings['minimum-battery-charge'].decimal) {
                            hasError =
                            this.errors['minimum-battery-charge'] = 'Minimum battery charge must be decimal'
                        } else if (!this.$v.notif_settings['minimum-battery-charge'].minValue) {
                            hasError =
                            this.errors['minimum-battery-charge'] = 'Minimum battery charge must be greater than zero'
                        }
                        if(hasError){
                            this.componentsErrors.push('')
                        }
                    }
                }
            },
            removeEmail(i){
                this.notif_settings.emails.splice(i,1)
                if(this.errors.emails) this.errors.emails.splice(i,1)
                if(!this.notif_settings.emails.length){
                    this.notif_settings.emails = ['']
                }
            },
            addEmail(){
                if(this.notif_settings.emails.length<5) this.notif_settings.emails.push('')
            },
            onCancel(){
                this.showMainList()
                this.$emit('close-dialog')
            },
            onSave(){
                this.validateSettigs()
                this.errors.emails = this.notif_settings.emails.reduce((out, email, i, emails) => {
                    let indx = emails.findIndex(e => e == email)
                    if (indx >= 0 && i > indx && !(out[i]>'')){
                        out[i] = this.$t('errors.This email already exists')
                    }
                    return out
                }, this.errors.emails || [])
                if (!this.errors.emails.length) delete this.errors.emails

                if(Object.keys(this.errors).length)
                    return false // stop here if form is invalid

                let title = `${this.$t('buttons.Save')} ${this.$t('Notifications.title.Notice settings')}`
                this.setPopup({
                    title,
                    methods: {
                        ok: () => this.saveSettings(),
                        //cancel: () => {},
                    }
                })
            },
            saveSettings(){
                this.notif_settings.emails = this.notif_settings.emails.filter(e => e > '')
                this.saveAppUserNotificationsSettings(this.notif_settings)
                .then((/*res*/) => {
                    //console.log(res)
                    this.errors = {}
                    this.$toast.success(this.$t("success!"))
                    this.$emit('close-dialog')
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        console.error(error.response)
                        if(error.response.status == 422){
                            this.errors = error.response.data.reduce((out, error) => {
                                out[error.field] = error.message
                                return out
                            }, {})
                            if(!this.errors.emails) this.errors.emails = []
                        }
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                    //console.error(error.config);
                })
            }
        },
        created() {
            let settings = {emails: [], ...this.getAppUser.notifications_settings}
            if(!settings.emails || !settings.emails.length) settings.emails = ['']
            this.notif_settings = {...settings, emails: [...settings.emails] }
            //this.emails = this.notif_settings['e-mail'].split(";")
            //console.log('NotificationsProps created', this.getAppUser.id, {...this.notif_settings})
        },
        updated() {
            //console.log('NotificationsProps updated', this.getAppUser.id, {...this.notif_settings})
        },
        mounted() {
            //console.log('NotificationsProps mounted', this.getAppUser.id, {...this.notif_settings})
        }
    }
</script>

<style scoped>

</style>